export const shops = [
  {
    name: "Taqueria Dos Hermanos",
    phone: "(559) 404-9560",
    address: "36629 Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Straw Hat Pizza",
    phone: "(559) 945-1234",
    address: "36618 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "New China Restaurant",
    phone: "(559) 945-2823",
    address: "36882 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "La Esperanza",
    phone: "(559) 945-9496",
    address: "36865 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Moreno's Taqueria La Esquinita",
    phone: "(559) 945-9608",
    address: "36905 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Huron Wireless Smoke Shop",
    phone: "(925) 917-2565/(661) 996-6096",
    address: "36951 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Taco Tren",
    phone: "(559) 763-6004",
    address: "36461 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Taqueria El Rinconcito Diaz",
    phone: "(559) 763-6060",
    address: "36650 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Huron General Store",
    phone: "(559) 945-9620",
    address: "36456 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Family Dollar",
    phone: "(559) 418-0118",
    address: "36301 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Dollar General",
    phone: "(559) 418-5267",
    address: "36281 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Rhino Liquor Market",
    phone: "(559) 945-9484",
    address: "36629 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Fiesta Latino",
    phone: "(559) 309-0757",
    address: "36869 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Speedo Mart (Sinclair)",
    phone: "(559) 945-9125",
    address: "36509 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Sinclair Gas Station",
    phone: "(559) 945-9125",
    address: "36509 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Krispy Krunchy Chicken (Sinclair)",
    phone: "(559) 945-9125",
    address: "36509 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Los Amigos Food Center",
    phone: "(559) 945-9502",
    address: "36210 Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Huron Valley Food",
    phone: "(559) 945-9373",
    address: "36220 Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Lassen Market",
    phone: "(559) 612-3154",
    address: "36668 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Jet Stop",
    phone: "(559) 763-6000",
    address: "36010 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Chevron",
    phone: "(559) 945-1500",
    address: "36700 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Angel Meat Market Corp",
    phone: "(559) 945-2902",
    address: "36539 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Manantial Bakery",
    phone: "(559) 945-7410",
    address: "16934 W 10th Street, Huron, CA 93234",
  },
  {
    name: "Wapo's Tacos",
    phone: "(559) 763-6028",
    address: "36402 S Lassen Ave, Huron, CA 93234",
  },
  {
    name: "AAA Smoke Shop",
    phone: "xxxxx",
    address: "36412 Lassen Ave, Huron, CA 93234",
  },
  {
    name: "Panaderia De Dios",
    phone: "(559) 945-9885",
    address: "17099-17045 Myrtle St, Huron, CA 93234",
  },
  {
    name: "Panaderia Rodriguez",
    phone: "(559) 945-9885",
    address: "17051 Myrtle St, Huron, CA 93234",
  },
];
