import React, { useEffect } from "react";
import "../../../stylesheets/paidForm.css";
import { findCompanyDetails } from "../../../actions/companyDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaidFormLogoSection from "../../common/paidComponents/paidFormLogoSection";
import { HANDLER_CUSTOMER_NAME } from "../../../reducers/customerNameReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../../reducers/customerAddressReducer";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../../reducers/deliveryInstructionReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../../reducers/customerEmailReducer";
import i18next from "i18next";
import { useStripePaidForm } from "../../customHooks/stripePaidFormHook";
import StripeNotConnected from "../../common/paidComponents/stripeNotConnected";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import { EMAIL_INPUT_FIELD, REMEMBER_SECTION, SELECTOR_INPUT_FIELD, TEXT_INPUT_FIELD, TEXTAREA_ADDRESS_FIELD, TEXTAREA_FIELD, TIME_INPUT_FIELD, VALUE_INPUT_FIELD } from "../../../util/formBuilderHelper";
import GenericOrderDetailsInputBuilder from "../../common/paidFormBuilders/genericOrderDetailsInputBuilder";
import GenericOrderPaymentPageBuilder from "../../common/paidFormBuilders/genericOrderPaymentPageBuilder";
import { HANDLER_REMEMBER_INFO } from "../../../reducers/rememberInfoReducer";
import { HANDLER_TIPS } from "../../../reducers/tipsReducer";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../../reducers/expectedPickupTimeReducer";
import { shops } from "./shops";
import useStateMapper from "../../customHooks/stateMapperHook";
import { HANDLER_RESTAURANT_NAME } from "../../../reducers/restaurantNameReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../../reducers/restaurantAddressReducer";
import { HANDLER_TOTAL_ORDER_COST } from "../../../reducers/totalOrderCostReducer";

// for info@eatumup.org

const CustomPaidForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const [stateMapper] = useStateMapper();
    const restaurantName = stateMapper(HANDLER_RESTAURANT_NAME);

    const companyInfo = useSelector(state => state.companyDetails);
    const [stripeDetails, isEstimating, estimationComplete, estimate, resetEstimation] = useStripePaidForm(companyInfo);

    const showPayment = estimationComplete;
    const showSpinner = isEstimating;

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, [params.id])

    useEffect(() => {
        if (companyInfo !== null)
            i18next.changeLanguage("en");
    }, [companyInfo?.getLanguage()]);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        estimate();
    }

    const handleBackToEdit = (ev) => {
        ev.preventDefault();
        resetEstimation();
    }

    useEffect(() => {
        const shop = shops.find(s => s.name === restaurantName);
        if (shop) {
            dispatch({type: HANDLER_RESTAURANT_PHONE_NUMBER, payload: shop.phone});
            dispatch({type: HANDLER_RESTAURANT_ADDRESS, payload: shop.address});
        }
    }, [restaurantName])

    if (stripeDetails === null || companyInfo === null) return <></>;

    const config = [
        {
            sectionHeader: {title: t(FORM_LOCALE_KEYS.DELIVER_TO)},
            sectionFields: [
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Full Name of Recipient",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_NAME,
                    prepopulate: true,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Phone Number of Recipient",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                    prepopulate: true,
                },
                {
                    fieldType: EMAIL_INPUT_FIELD,
                    placeholder: "Email Address of Recipient",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_EMAIL,
                    prepopulate: true,
                },
                {
                    fieldType: TEXTAREA_ADDRESS_FIELD,
                    placeholder: "Street Address of Recipient",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_ADDRESS,
                    prepopulate: true,
                },
                {
                    fieldType: REMEMBER_SECTION,
                    label: "Remember delivery information",
                    required: false,
                    handlerType: HANDLER_REMEMBER_INFO,
                },
            ]
        },
        {
            sectionHeader: {title: "Pick-up from"},
            sectionFields: [
                {
                    fieldType: SELECTOR_INPUT_FIELD,
                    placeholder: "Restaurant",
                    required: true,
                    handlerType: HANDLER_RESTAURANT_NAME,
                    selectionOptions: [
                        {label: "Which Local Restaurant/Store Did You Order From?", value: "", disabled: true},
                        ...shops.map(s => {
                            return {label: s.name, value: s.name};
                        })
                    ]
                },
                {
                    fieldType: "NONE",
                    handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                },
                {
                    fieldType: "NONE",
                    handlerType: HANDLER_RESTAURANT_ADDRESS,
                },
                {
                    fieldType: VALUE_INPUT_FIELD,
                    placeholder: "Total Amount Of Your Order?",
                    required: true,
                    handlerType: HANDLER_TOTAL_ORDER_COST,
                },
                {
                    fieldType: TIME_INPUT_FIELD,
                    placeholder: "Local Restaurant/Store Pickup Time?",
                    required: true,
                    handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                },
                {
                    fieldType: VALUE_INPUT_FIELD,
                    placeholder: "Tip",
                    required: false,
                    handlerType: HANDLER_TIPS,
                },
                {
                    fieldType: TEXTAREA_FIELD,
                    placeholder: "Delivery Instructions",
                    required: false,
                    handlerType: HANDLER_DELIVERY_INSTRUCTION,
                },
            ]
        },
    ]

    if(!stripeDetails.isConnected()) return (
        <StripeNotConnected>
            <GenericOrderDetailsInputBuilder config={config} visible={!showPayment} loading={showSpinner}/>
        </StripeNotConnected>
    );

    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment} backToEdit={handleBackToEdit}/>
            <div className="container-md">
                <form className={`col-lg-6 offset-lg-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}>
                    <GenericOrderDetailsInputBuilder config={config} visible={!showPayment} loading={showSpinner}/>
                </form>
            </div>
            <div id="paymentSection" className={`container-md ${showPayment ? "" : "d-none"}`}>
                <div className="col-lg-6 offset-lg-3">
                    <GenericOrderPaymentPageBuilder config={config} visible={showPayment}
                        details={{}}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomPaidForm;
