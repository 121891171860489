import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    fieldJSONCreator, LAYOUT_DEFAULT, REMEMBER_SECTION, sectionTitleJSONCreator, TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import { OrderEmailTemplate4B } from "../../util/emailTemplates/orderEmailTemplate4B";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_ORDER_NUMBER } from "../../reducers/orderNumberReducer";

// for mohamadmzyek@gmail.com


const OrderForm35 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();


    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator("Delivery To (Leverera Till)"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, <span style={{fontWeight: 600, fontSize: "18px"}}>Order Number (Order Nummer)</span>, '', true, LAYOUT_DEFAULT, HANDLER_ORDER_NUMBER),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, <span style={{fontWeight: 600, fontSize: "18px"}}>Customer Address (Kund Adress)</span>, '', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXT_INPUT_FIELD, <span style={{fontWeight: 600, fontSize: "18px"}}>Customer Phone Number (Kund Telefonnummer)</span>, '', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Pickup From (Leverans Från)"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Company Name (Företagsnamn)", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Company Address (Företagsadress)", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Company Phone Number (Företags Telefonnummer)", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true),
                fieldJSONCreator(REMEMBER_SECTION, "Remember Pickup Information", '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO),
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, []);

    const template = onlyDispatcherMail(OrderEmailTemplate4B)

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm35;
