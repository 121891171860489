import { useSelector } from "react-redux";
import { HANDLER_RESTAURANT_ADDRESS } from "../../../reducers/restaurantAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../../reducers/restaurantNameReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../../reducers/restaurantPhoneNumberReducer";
import { DATE_INPUT_FIELD, EMAIL_INPUT_FIELD, REMEMBER_SECTION, SELECTOR_INPUT_FIELD, TEXT_INPUT_FIELD, TEXTAREA_ADDRESS_FIELD, TEXTAREA_FIELD, TIME_24H_INPUT_FIELD, TIME_INPUT_FIELD, VALUE_INPUT_FIELD } from "../../../util/formBuilderHelper";
import AddressInput from "../components/addressInput";
import DateInput from "../components/dateInput";
import NameInput from "../components/nameInput";
import RememberInfo from "../components/rememberInfo";
import Selector from "../components/selector";
import TextAreaInput from "../components/textAreaInput";
import Time24hInput from "../components/time24hInput";
import TimeInput from "../components/timeInput";
import ValueInput from "../components/valueInput";
import Card from "../paidComponents/card";
import CardTitle from "../paidComponents/cardTitle";
import PaidFormError from "../paidComponents/paidFormError";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import { useTranslation } from "react-i18next";
import EmailInput from "../components/emailInput";
import AddressInputWithW3W, { ADDRESS_INPUT_WITH_W3W } from "../components/addressInputWithW3W";


const GenericOrderDetailsInputBuilder = ({config, loading, submitBtn}) => {
    const {t} = useTranslation();
    const companyInfo = useSelector(state => state.companyDetails);
    const deliveryOnly = companyInfo?.isDeliveryOnly();
    const deliveryAddress = companyInfo?.getDeliveryOnlyAddress();
    const deliveryPhone = companyInfo?.getDeliveryOnlyPhone();
    const companyName = companyInfo?.getCompanyName();

    const getField = (field) => {
        let deliveryMetadata
        switch (field.handlerType) {
            case HANDLER_RESTAURANT_NAME:
                deliveryMetadata = {deliveryOnly: deliveryOnly, info: companyName};
                break;
            case HANDLER_RESTAURANT_PHONE_NUMBER:
                deliveryMetadata = {deliveryOnly: deliveryOnly, info: deliveryPhone};
                break;
            case HANDLER_RESTAURANT_ADDRESS:
                deliveryMetadata = {deliveryOnly: deliveryOnly, info: deliveryAddress};
                break;
            default:
                deliveryMetadata = {}
                break;
        }

        switch (field.fieldType) {
            case TEXT_INPUT_FIELD:
                return <NameInput config={field} {...deliveryMetadata} />
            case EMAIL_INPUT_FIELD:
                return <EmailInput config={field} {...deliveryMetadata} />
            case TEXTAREA_FIELD:
                return <TextAreaInput config={field} {...deliveryMetadata} />
            case TEXTAREA_ADDRESS_FIELD:
                return <AddressInput config={field} {...deliveryMetadata} />
            case ADDRESS_INPUT_WITH_W3W:
                return <AddressInputWithW3W config={field} {...deliveryMetadata} />
            case DATE_INPUT_FIELD:
                return <DateInput config={field} {...deliveryMetadata} />
            case TIME_INPUT_FIELD:
                return <TimeInput config={field} {...deliveryMetadata} />
            case TIME_24H_INPUT_FIELD:
                return <Time24hInput config={field} {...deliveryMetadata} />
            case VALUE_INPUT_FIELD:
                return <ValueInput config={field} {...deliveryMetadata} />
            case REMEMBER_SECTION:
                return <RememberInfo config={field} {...deliveryMetadata} />
            case SELECTOR_INPUT_FIELD:
                return <Selector config={field} {...deliveryMetadata} />
            default:
                return <></>
        }
    }

    const getSection = (section) => {
        return <Card>
            <CardTitle title={section.sectionHeader.title} />
            {section.sectionFields.map(field => getField(field))}
        </Card>
    }

    return (
        <>
            <div>
                {config.map(section => getSection(section))}
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && (submitBtn ?? t(FORM_LOCALE_KEYS.CHECKOUT))}
                </button>
            </div>
            <div className="mt-1">
                <PaidFormError />
            </div>
        </>
    );
}
export default GenericOrderDetailsInputBuilder