import React, { useEffect } from "react";
import {useDispatch} from "react-redux";
import FieldLabel from "./fieldLabel";

const Selector = ({config}) => {
    const dispatch = useDispatch();

    const getDefaultValue = (config) => {
        let selectedOption = config.selectionOptions.find((selectable) =>  selectable?.selected);
        return selectedOption ? selectedOption.value : config.selectionOptions[0].value;
    }

    const getOptions = (config) => {
        return config.selectionOptions.map((selectable) => {
            return <option key={selectable.value} value={selectable.value} disabled={selectable?.disabled ?? false}>{selectable.label}</option>
        })
    }

    const inputChanged = (ev) => {
        dispatch({type: config.handlerType, payload: ev.target.value});
    }

    useEffect(() => {
        if(typeof config === undefined)
            return;
        resetInput();

        const f = document.getElementById("externalOrderForm");
        if(f) f.addEventListener("reset", resetInput);
        return () => {if(f) f.removeEventListener("reset", resetInput)};
    }, []);

    if (typeof config === "undefined") {
        return null;
    }

    const resetInput = () => {
        dispatch({type: config.handlerType, payload: getDefaultValue(config)});
    }

    return (
        <div>
            <FieldLabel config={config}/>
            <select className="form-select" aria-label="Package Size select" required={config?.required ?? false} onChange={inputChanged} defaultValue={getDefaultValue(config)}>
                {getOptions(config)}
            </select>
        </div>
    )
}

export default Selector;
